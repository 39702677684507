<template>
  <!-- 专利任务-待办任务 -->
  <div>
    <div class="topUsers" style="margin-top: 24px;">
      <div>
        <el-input
          clearable
          style="width: 224px;"
          v-model="taskParams.company_name"
          placeholder="请输入企业名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-input
          clearable
          style="width: 224px;"
          v-model="taskParams.patent_name"
          placeholder="请输入专利名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
      <el-button class="add" @click="handleAdd()">新增</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="Loading···"
      :data="tasklist.data"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.company_name ? scope.row.company_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="patent_name" label="专利名" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.patent_name ? scope.row.patent_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="app_code" label="专利号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.app_code ? scope.row.app_code : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.create_time ? scope.row.create_time : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="150px" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <!-- <el-button class="edit" size="mini" @click="handleInfo(scope.row.patent_id)">编辑</el-button> -->
          <el-button class="edit" size="mini" @click="handleFinish(scope.row.id)">完成</el-button>
          <el-button class="del" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="tasklist.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="taskParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="taskParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="tasklist.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      keywords: "",
      tasklist: {

      },
      taskParams: {
        status: '0',
      },
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/project_patent/patent_mission_list', { params: { ...this.taskParams } }).then((res) => {
        this.tasklist = res.data
      })
    },
    // 完成按钮
    handleFinish (id) {
      this.axios.post('/api/project_patent/finish_mission', { id: id }).then((a) => {
        this.getList()
      })
    },
    // 查询按钮
    handleSearch () {
      this.axios.get('/api/project_patent/patent_mission_list', { params: { ...this.taskParams } }).then((res) => {
        this.tasklist = res.data
      })
    },
    // 新增按钮
    handleAdd () {
      this.$router.push('/patent/task_edit')
    },
    // 编辑按钮
    handleInfo (id) {
      this.$router.push({ path: '/patent/task_edit', query: { id: id } })
    },
    // 删除按钮
    handleDel (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/project_patent/delete_mission", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange () {

    },
    handleCurrentChange () {

    },
  },
}
</script>

<style>
</style>