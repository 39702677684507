<template>
  <div class="KCmodule">
    <Header title="专利任务" index="首页" titleOne="工程专利" beforeTitle="专利任务" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="待办任务" name="first">
          <Wait ref="wait" />
        </el-tab-pane>
        <el-tab-pane label="已完成" name="second">
          <Complte ref="com" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Wait from '../../components/patentTask/waitTask.vue'
import Complte from '../../components/patentTask/complteTask.vue'
export default {
  data () {
    return {
      activeName: 'first',
    }
  },
  components: {
    Wait,
    Complte,
  },
  created () {

  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab, event);
      if (tab.name == 'first') {
        this.$refs.wait.getList()
      } else if (tab.name == 'second') {
        this.$refs.com.getList()
      }
    },
    getList () {

    },
  },
}
</script>

<style>
</style>